import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'default',
    component: () => import('../views/AutoPay.vue')
  },
  {
    path: '/auto-pay',
    name: 'autoPay',
    component: () => import('../views/AutoPay.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
